export const electives = [
  {
    id: "PC",
    title: "Payment Card Industry Awareness",
    subTitle:
      "This special unit is for individuals and businesses that handle credit card information",
    points: [
      "Understanding the standards and requirements set by the Payment Card Industry Security Standards Council for handling credit card data",
      "Best practices for PCI compliance and protecting sensitive credit card data",
    ],
  },
  {
    id: "CS",
    title: "Cloud Security",
    subTitle:
      "This special unit is for businesses that use the cloud or are considering moving to the cloud",
    points: [
      "Understanding the risks and challenges associated with cloud computing",
      "Best practices for securing cloud-based systems and data",
      "How to avoid common cloud security pitfalls, such as weak passwords or unsecured configurations",
    ],
  },
  {
    id: "IR",
    title: "Incident Response and Business Continuity",
    subTitle: "This special unit is for Businesses Managers and IT Staff",
    points: [
      "An overview of the importance of incident response and business continuity planning",
      "Best practices for incident response, such as identifying and containing cyber threats, assessing the impact, and notifying appropriate parties",
      "How to develop and implement a business continuity plan to minimise the impact of a cyber incident on business operations and recover quickly",
    ],
  },
  {
    id: "CA",
    title: "Cyber Security Auditing",
    subTitle: "This special unit is for Businesses Managers and IT Staff",
    points: [
      "Understanding the goals and objectives of a cyber security audit",
      "Familiarizing oneself with regulatory compliance standards and industry best practices",
      "Identifying and assessing cyber risks and vulnerabilities in an organization's systems and processes",
      "Evaluating the effectiveness of an organization's current security controls",
      "Assessing the physical security of an organization's facilities and equipment",
      "Reviewing policies and procedures related to information security",
      "Testing the resilience of an organization's systems to various cyber threats",
      "Conducting vulnerability assessments and penetration testing",
      "Developing risk mitigation and remediation strategies",
      "Creating a comprehensive audit report that identifies areas of concern and provides recommendations for improvement",
    ],
  },
];
