import { useState } from "react";
import { API, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import Validator from "validatorjs";
import {
  Button,
  PhoneNumberField,
  SelectField,
  TextField,
} from "@aws-amplify/ui-react";

import { createCandidate } from "../../graphql/mutations";

import "@aws-amplify/ui-react/styles.css";
import "./ContactForm.css";

const ContactForm = () => {
  const [showForm, setShowForm] = useState(true);

  const [gnameError, setGnameError] = useState(false);
  const [fnameError, setFnameError] = useState(false);
  const [bnameError, setBnameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [interestError, setInterestError] = useState(false);

  function restErrors() {
    //set back the errors (if any)
    setGnameError(false);
    setFnameError(false);
    setBnameError(false);
    setEmailError(false);
    setPhoneError(false);
    setInterestError(false);
    return;
  }

  async function createNewCandidate(event) {
    event.preventDefault();
    const form = new FormData(event.target);
    let data = {
      gname: form.get("gname"),
      fname: form.get("fname"),
      bname: form.get("bname"),
      email: form.get("email"),
      phone:
        form.get("dial_code") +
        parseInt(form.get("phone").replace(/\D/g, ""), 10),
      interest: form.get("interest"),
    };

    // basic clean and valid
    let rules = {
      gname: "required",
      fname: "required",
      email: "required|email",
      phone: "required",
      interest: "required",
    };

    let validation = new Validator(data, rules);

    if (validation.passes()) {
      restErrors();

      try {
        await API.graphql({
          query: createCandidate,
          variables: { input: data },
          authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        event.target.reset();
        setShowForm(false);
        //make the form go away!
      } catch (err) {
        if (err.errors) {
          err.errors.forEach((msg) => {
            let thisError = msg.message.split("'")[1];

            switch (thisError) {
              case "gname":
                setGnameError(true);
                break;
              case "fname":
                setFnameError(true);
                break;
              case "bname":
                setBnameError(true);
                break;
              case "email":
                setEmailError(true);
                break;
              case "phone":
                setPhoneError(true);
                break;
              case "interest":
                setInterestError(true);
                break;
              default:
                break;
            }
          });
        } else {
          console.log(err);
        }
      }
    } else {
      restErrors();

      const valerr = validation.errors.errors;
      for (const thisError in valerr) {
        switch (thisError) {
          case "gname":
            setGnameError(true);
            break;
          case "fname":
            setFnameError(true);
            break;
          case "bname":
            setBnameError(true);
            break;
          case "email":
            setEmailError(true);
            break;
          case "phone":
            setPhoneError(true);
            break;
          case "interest":
            setInterestError(true);
            break;
          default:
            break;
        }
      }
    }
  }

  return (
    <>
      {showForm && (
        <div id="contactForm">
          <h2>Contact Us</h2>
          <form onSubmit={createNewCandidate}>
            <div className="contactFields">
              <TextField
                name="gname"
                placeholder="Given Name"
                label="Given Name"
                labelHidden
                variation="quiet"
                required
                hasError={gnameError}
                errorMessage="The given name has an error! 😱"
              />
              <TextField
                name="fname"
                placeholder="Family Name"
                label="Family Name"
                labelHidden
                variation="quiet"
                required
                hasError={fnameError}
                errorMessage="The family name has an error! 😱"
              />
              <TextField
                name="bname"
                placeholder="Business Name"
                label="Business Name"
                labelHidden
                variation="quiet"
                hasError={bnameError}
                errorMessage="The business name has an error! 😱"
              />
              <TextField
                name="email"
                placeholder="Email"
                label="Email"
                labelHidden
                variation="quiet"
                required
                hasError={emailError}
                errorMessage="Not a valid email! 😱"
              />
              <PhoneNumberField
                defaultDialCode="+61"
                label="Phone Number"
                labelHidden
                name="phone"
                isRequired={true}
                dialCodeName="dial_code"
                variation="quiet"
                hasError={phoneError}
                errorMessage="Not a valid phone number! 😱"
              />
              <SelectField
                name="interest"
                label="Interest"
                labelHidden
                descriptiveText="What is main interest?"
                variation="quiet"
                required
                hasError={interestError}
                errorMessage="There is a select error! 😱"
              >
                <option value="awareness">Awareness Training</option>
                <option value="audit">Network Audit</option>
                <option value="penetration">Penetration Testing</option>
                <option value="all">All of the above</option>
              </SelectField>

              <Button type="submit">Submit</Button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default ContactForm;
