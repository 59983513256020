export const auditPoints = [
  {
    id: "1",
    title: "Identification of potential vulnerabilities",
    points: [
      "This involves a thorough review of an organization's networks, systems and software to identify potential security weaknesses or vulnerabilities that could be exploited by hackers or other malicious actors.",
    ],
  },
  {
    id: "2",
    title: "Evaluation of existing policies and procedures",
    points: [
      "We review existing policies and procedures to ensure that they are up-to-date, comply with relevant regulations and standards, and are effective at mitigating cyber security risks.",
    ],
  },
  {
    id: "3",
    title: "Review of access controls and authentication",
    points: [
      "We evaluate access controls and authentication mechanisms to identify any potential weaknesses or vulnerabilities that could be exploited by hackers or other malicious actors.",
    ],
  },
  {
    id: "4",
    title:
      "Analysis of threat management processes and incident response plans",
    points: [
      "We review existing threat management processes and incident response plans to ensure that they are effective at mitigating cyber security risks and responding to potential security incidents.",
    ],
  },
  {
    id: "5",
    title: "Review of backup and disaster recovery procedures",
    points: [
      "We review existing backup and disaster recovery procedures to ensure that they are effective at restoring critical systems and data in the event of a security incident.",
    ],
  },
  {
    id: "6",
    title:
      "Evaluation of physical security measures to prevent unauthorized access",
    points: [
      "We evaluate physical security measures to prevent unauthorized access to an organization's systems and data.",
    ],
  },
  {
    id: "7",
    title:
      "Identification of security risks associated with third-party vendors and suppliers",
    points: [
      "We identify potential security risks associated with third-party vendors and suppliers, and provide recommendations for mitigating those risks.",
    ],
  },
  {
    id: "8",
    title: "Assessment of employee security training and awareness programs",
    points: [
      "We assess employee security training and awareness programs to ensure that employees are educated and aware of cyber security risks and best practices.",
    ],
  },
  {
    id: "9",
    title: "Development of a comprehensive audit report",
    points: [
      "We develop a comprehensive audit report that identifies potential vulnerabilities and weaknesses, and provides recommendations for improving an organization's cyber security posture.",
    ],
  },
  {
    id: "10",
    title: "Ongoing support and guidance",
    points: [
      "If required, we provide ongoing support and guidance to help organizations implement recommended changes and improve their overall cyber security posture over time.",
    ],
  },
];
