import "./Audit.css";
import listImg from "../../assets/audit_tick.png";
import { TopNav, Footer, ItemCard } from "../../components";
import { auditPoints } from "./audit_points";

const Audit = () => {
  return (
    <>
      <TopNav />
      <div className="container">
        <main className="main">
          <h1>Cyber Security Audit</h1>
          <p className="body-text">
            A cyber security audit is a vital tool to help businesses identify
            potential vulnerabilities, assess existing security measures, and
            develop a plan to mitigate risks and protect sensitive data. By
            proactively addressing potential security risks, businesses can
            avoid costly data breaches, protect their customers' sensitive
            information, and maintain the trust and loyalty of their
            stakeholders. A cyber security audit is an essential step towards
            protecting an organization's assets and reputation in today's
            ever-evolving digital landscape.
          </p>
          <h2>Cyber Security Audit Overview</h2>
          <div className="items">
            {auditPoints.map((item, index) => (
              <ItemCard
                key={index}
                id={item.id}
                srcImg={listImg}
                altTxt={item.title}
                title={item.title}
                subTitle={false}
                points={item.points}
              />
            ))}
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
};

export default Audit;
