import { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";

import { Flex, Heading, Text, View } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import { listCandidates } from "../../graphql/queries";

const Dashboard = () => {
  const [candidates, setCandidates] = useState([]);

  useEffect(() => {
    fetchCandidates();
  }, []);

  async function fetchCandidates() {
    const CandidateList = await API.graphql(graphqlOperation(listCandidates));
    const candidatesFromAPI = CandidateList.data.listCandidates.items;
    setCandidates(candidatesFromAPI);
  }

  return (
    <View className="dashboard">
      <Heading level={1}>Current Candidates</Heading>
      <View margin="3rem 0">
        {candidates.map((candidate) => (
          <Flex
            key={candidate.id || candidate.email}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Text as="strong" fontWeight={700}>
              {candidate.id}
            </Text>
            <Text as="span">{candidate.email}</Text>
          </Flex>
        ))}
      </View>
    </View>
  );
};

export default Dashboard;
