import "./ErrorPage.css";
import { TopNav, Footer } from "../../components";

const ErrorPage = () => {
  return (
    <>
      <TopNav />
      <div className="container">
        <main className="main">
          <div id="error">
            <h1>Error</h1>
            <p>
              There seems to be an errror with the thing you were looking for.
              Please click one of the links above to go to the correct page.
            </p>
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
};

export default ErrorPage;
