export const questions = [
  {
    id: 1,
    title: "Is your training face to face?",
    answer:
      "Yes, while we are a tech business we believe that training, especially training like cyber awareness training, should be done face to face. It is far less about the technology than most people think.",
    note: "All training is instructor lead.",
  },
  {
    id: 2,
    title: "Do trainees need a computer to do cyber awareness training?",
    answer:
      "Actually, no. We will access the internet, of course, but the room only needs to be set up like any other training room.  We do require a 'screen' that we can connect to that is big enough for all the trainees to see.",
    note: "Trainees do not need a computer.",
  },
];
