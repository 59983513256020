export const modules = [
  {
    id: "I",
    title: "Introduction",
    points: [
      "Explanation of why cybersecurity awareness training is important",
      "Overview of the training program and what participants can expect to learn",
    ],
  },
  {
    id: "II",
    title: "Common Cyber Threats",
    points: [
      "Types of cyber threats, including malware, phishing, and ransomware",
      "How cyber threats are delivered, such as through email, messaging, or social media",
      "How to identify and prevent cyber threats, including recognising suspicious links, attachments, or messages",
    ],
  },
  {
    id: "III",
    title: "Password Management",
    points: [
      "The importance of strong passwords and how to create them",
      "Best practices for password management, including using a password manager and changing passwords regularly",
      "How to avoid common password pitfalls, such as using the same password for multiple accounts",
    ],
  },
  {
    id: "IV",
    title: "Email and Messaging Security",
    points: [
      "An overview of common email and messaging scams, such as phishing or social engineering",
      "How to identify and avoid these scams",
      "Best practices for safe email and messaging practices, such as verifying the sender before clicking on a link or downloading an attachment",
    ],
  },
  {
    id: "V",
    title: "Safe Internet Browsing",
    points: [
      "Common risks associated with internet browsing, such as downloading malware or visiting malicious websites",
      "How to identify and avoid risky websites and downloads",
      "Best practices for safe internet browsing, such as using a trusted browser and enabling pop-up blockers",
    ],
  },
  {
    id: "VI",
    title: "Social Engineering",
    points: [
      "A more in-depth explanation of social engineering and the tactics cybercriminals use",
      "How to recognise and avoid social engineering attacks, such as pretexting or baiting",
      "Best practices for responding to social engineering attacks, including reporting any suspicious activity",
    ],
  },
  {
    id: "VII",
    title: "Mobile Device Security",
    points: [
      "An overview of the risks associated with mobile devices, such as unauthorised access or malware",
      "Best practices for mobile device security, including using a strong passcode and installing security software",
      "How to protect mobile devices from cyber threats, such as keeping software up-to-date and avoiding unsecured Wi-Fi networks",
    ],
  },
  {
    id: "VIII",
    title: "Working Remotely",
    points: [
      "Best practices for securing remote access to company data and systems",
      "How to use secure communication tools for remote work",
      "How to avoid common risks associated with remote work, such as using unsecured public Wi-Fi networks",
    ],
  },
  {
    id: "IX",
    title: "Physical Security",
    points: [
      "An explanation of the importance of physical security, such as securing devices and equipment",
      "Best practices for securing physical devices and equipment, including locking devices and not leaving them unattended",
      "How to protect against physical security risks, such as tailgating or unauthorised access",
    ],
  },
  {
    id: "X",
    title: "Data Protection and Privacy",
    points: [
      "An overview of the importance of data protection and privacy, including the risks associated with data breaches",
      "Best practices for data protection and privacy, such as keeping sensitive information secure and reporting any suspicious activity",
      "How to identify and report data breaches, including steps to take if personal or sensitive information has been compromised",
    ],
  },
  {
    id: "XI",
    title: "Travel Safety and Cybersecurity",
    points: [
      "Best practices for maintaining cybersecurity while traveling, such as using a virtual private network (VPN) and avoiding unsecured public Wi-Fi networks",
      "How to protect devices and information while traveling, including securing devices and avoiding suspicious websites and downloads",
      "How to respond to cybersecurity incidents while traveling, including reporting any suspicious activity and taking steps to protect sensitive information",
    ],
  },
  {
    id: "XII",
    title: "Review and Assessment of Learning Outcomes",
    points: [
      "Recap of key takeaways from the training program",
      "Explanation of the importance of continued cybersecurity awareness and education",
      "Encouragement for participants to apply what they've learned and stay vigilant in protecting against cyber threats, both at home and while traveling.",
    ],
  },
];
