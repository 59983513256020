import "./Faq.css";
import { questions } from "./data.js";
import Question from "./Question";
import { TopNav, Footer } from "../../components";

const Faq = () => {
  return (
    <>
      <TopNav />
      <div className="container">
        <main className="main">
          <h1>FAQs</h1>
          <p className="body-text">
            Here are a few of the commonly asked question that you might like
            know.
          </p>
          <div id="questions">
            {questions.map((question) => (
              <Question
                key={question.id}
                title={question.title}
                answer={question.answer}
                note={question.note}
              />
            ))}
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
};

export default Faq;
