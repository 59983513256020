import React, { useState } from "react";
import ScrollToTop from "../scrolltotop/ScrollToTop";
import { useNavigate } from "react-router-dom";
import { useAuthenticator, Button } from "@aws-amplify/ui-react";
import { AiOutlineBars } from "react-icons/ai";
import { RiCloseLine } from "react-icons/ri";
import logo from "../../assets/infosafe_logo.png";
import banner from "../../assets/banner.png";
import "./TopNav.css";

const TopNav = () => {
  const { route, signOut } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ]);

  const navigate = useNavigate();

  function logOut() {
    signOut();
    navigate("/");
  }

  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className="container">
      <nav style={{ backgroundImage: `url(${banner})` }}>
        <a href="/">
          <div id="logo">
            <img src={logo} alt="infoSafe Cyber Security Logo" />
            <div id="logo-text">
              <h1>infoSafe.biz</h1>
              <h2>Cyber Security</h2>
            </div>
          </div>
        </a>
        <div className="menu">
          <ul id={showMenu ? "nav-links-mobile" : "nav-links-mobile-hide"}>
            <li className="nav-btn">
              <Button onClick={() => navigate("/awareness")}>
                Cyber Awareness
              </Button>
            </li>
            <li className="nav-btn">
              <Button onClick={() => navigate("/audit")}>Security Audit</Button>
            </li>
            <li className="nav-btn">
              <Button onClick={() => navigate("/penetration")}>
                Penetration Testing
              </Button>
            </li>
            <li className="nav-btn">
              <Button onClick={() => navigate("/faq")}>FAQ</Button>
            </li>
            {route !== "authenticated" ? (
              <li className="nav-btn">
                <Button onClick={() => navigate("/admin")}>Login</Button>
              </li>
            ) : (
              <>
                <li>
                  <Button onClick={() => navigate("/admin")}>Admin</Button>
                </li>
                <li className="nav-btn">
                  <Button onClick={() => logOut()}>Logout</Button>
                </li>
              </>
            )}
          </ul>
        </div>
        <div className="menu-icons" onClick={toggleMenu}>
          {showMenu ? (
            <RiCloseLine color="#701215" size={30} />
          ) : (
            <AiOutlineBars color="#701215" size={30} />
          )}
        </div>
      </nav>
      <ScrollToTop />
    </div>
  );
};

export default TopNav;
