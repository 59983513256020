import "./Awareness.css";
import { TopNav, Footer, ItemCard } from "../../components";
import { modules } from "./general_course_outline";
import { electives } from "./elective_outline";

const Awareness = () => {
  return (
    <>
      <TopNav />
      <div className="container">
        <main className="main">
          <h1>Cyber Awareness Training</h1>
          <p className="body-text">
            Cyber awareness training is a crucial component of any
            organization's cybersecurity strategy, helping employees to
            recognize and respond to cyber threats and attacks. Our program
            covers a range of topics such as phishing, social engineering,
            password security, malware, and other common cyber threats.
          </p>
          <p className="body-text">
            By investing in a comprehensive cyber awareness training program,
            organizations can empower their employees to play an active role in
            protecting against cyber threats and minimizing the risk of data
            breaches and other security incidents. This can ultimately help to
            improve the overall cybersecurity posture of the organization and
            protect against financial loss, reputational damage, and other
            negative consequences of cyber attacks.
          </p>
          <h2>General Cyber Awareness Training Program</h2>
          <div className="items">
            {modules.map((item, index) => (
              <ItemCard
                key={index}
                id={item.id}
                srcImg={`/images/awareness/${item.id}_img.png`}
                altTxt={item.title}
                title={item.title}
                subTitle={false}
                points={item.points}
              />
            ))}
          </div>
          <h2>Additional Special Units</h2>
          <p className="body-text">
            Special Units can be taught individually or added, time permitting,
            to the general Cyber Awareness Training Program. These units are
            targeted at specific areas of understanding that may not be relevant
            to all individuals or workplaces. In some cases, they are
            specifically targeted at management or the technical department.
          </p>
          <div className="items">
            {electives.map((item, index) => (
              <ItemCard
                key={index}
                id={item.id}
                srcImg={`/images/awareness/${item.id}_img.png`}
                altTxt={item.title}
                title={item.title}
                subTitle={item.subTitle}
                points={item.points}
              />
            ))}
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
};

export default Awareness;
