import React from "react";
import ReactDOM from "react-dom/client";

import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import { Authenticator } from "@aws-amplify/ui-react";

import "./index.css";
import "@aws-amplify/ui-react/styles.css";

//setup route
import { createBrowserRouter, RouterProvider } from "react-router-dom";

//define the page
import {
  Admin,
  Audit,
  Awareness,
  Dashboard,
  ErrorPage,
  Faq,
  Home,
  Penetration,
} from "./pages";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/awareness",
    element: <Awareness />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/audit",
    element: <Audit />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/penetration",
    element: <Penetration />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/faq",
    element: <Faq />,
  },
  {
    path: "/admin",
    element: <Admin />,
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
    ],
  },
]);

Amplify.configure(awsExports);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Authenticator.Provider>
    <RouterProvider router={router} />
  </Authenticator.Provider>
);
