import "./ItemCard.css";

const ItemCard = ({ id, srcImg, altTxt, title, subTitle, points }) => {
  const listItems = points.map((item, index) => <li key={index}>{item}</li>);

  return (
    <div className="itemCard">
      <div className="itemImage">
        <img src={srcImg} alt={altTxt} aria-hidden="true" />
      </div>
      <div className="itemDetails">
        <div className="itemTitle">
          <h3>
            {id}. {title}
          </h3>
          {subTitle && <h4>{subTitle}</h4>}
          {points.length > 0 && (
            <div className="itemPoints">
              <ul>{listItems}</ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemCard;
