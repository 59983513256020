import "./CatCard.css";

const CatCard = ({ srcImg, altTxt, title, text }) => {
  return (
    <div className="category">
      <div className="catImage">
        <img src={srcImg} alt={altTxt} aria-hidden="true" />
      </div>
      <div className="catTitle">
        <h3>{title}</h3>
      </div>
      <div className="catText">
        <p>{text}</p>
      </div>
    </div>
  );
};

export default CatCard;
