import { ContactForm } from "../../components";

import "./Footer.css";
import logo from "../../assets/infosafe_logo.png";

const Footer = () => {
  return (
    <div className="container">
      <footer>
        <div id="footer-main">
          <div id="footer-left">
            <img src={logo} alt="InfoSafe" aria-hidden="true" />
            <p>
              &copy; 2004 - {new Date().getFullYear()}. INFOSAFE BIZ
              <br /> A division of IOW PTY LTD
            </p>
            <hr />
            <p>
              <strong>AUSTRALIA : SINGAPORE : GLOBAL</strong>
            </p>
          </div>
          <div id="footer-right">
            <ContactForm />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
