import "./Home.css";
import { TopNav, Footer, CatCard } from "../../components";
import awareness from "../../assets/awareness_category.png";
import network from "../../assets/network_category.png";
import penetration from "../../assets/penetration_category.png";
import isafe_front from "../../assets/isafe_front.png";

const Home = () => {
  return (
    <>
      <TopNav />
      <div className="container">
        <main className="main">
          <h1>Information Safety is our Business</h1>
          <p className="body-text">
            Welcome to our website! Since 2004, we have been dedicated to
            providing expert cybersecurity advice, educational programs, and
            professional services to help you stay safe in an increasingly
            digital world. Our team of experts is committed to helping you
            protect yourself and your organization from cyber threats, including
            internet security, hacking, malware, and more. Whether you're an
            individual looking to improve your online security or a business
            seeking to protect your network, we have the tools and resources you
            need to succeed. Browse our site to learn more, and don't hesitate
            to contact us with any questions or feedback. Thank you for
            visiting!
          </p>
          <h2>Three Key Areas</h2>
          <p className="body-text">
            At infoSafe.biz, we offer a range of essential cybersecurity
            products to help our clients stay safe and secure online. Our Cyber
            Awareness Training provides individuals and employees with the
            knowledge and tools they need to identify and prevent potential
            cyber threats. Our Network Security Auditing service helps
            organizations identify vulnerabilities and assess risks to prevent
            cyber attacks and data breaches. Finally, our Penetration Testing
            service simulates cyber attacks to identify network and system
            vulnerabilities and strengthen overall cybersecurity. Together,
            these three products provide a comprehensive approach to online
            security, ensuring that our clients can protect themselves and their
            organizations from the latest cyber threats.
          </p>
          <div id="categories">
            <a href="/awareness">
              <CatCard
                srcImg={awareness}
                altTxt="Cyber Awareness Training"
                title="Cyber Awareness Training"
                text="Cyber awareness training helps individuals and employees understand potential risks and threats in the digital world. By becoming more knowledgeable about how to protect personal information and how to recognize things like phishing scams, individuals can minimize the risk of a successful cyber attack."
              />
            </a>
            <a href="/audit">
              <CatCard
                srcImg={network}
                altTxt="Network Security Auditing"
                title="Network Security Auditing"
                text="Network security auditing is crucial for identifying vulnerabilities and assessing risks in your organization's network and systems of work. By conducting regular audits, you can proactively prevent cyber attacks and data breaches, protecting your business and its assets including your clients personal data."
              />
            </a>
            <a href="/audit">
              <CatCard
                srcImg={penetration}
                altTxt="Penetration Testing"
                title="Penetration Testing"
                text="Penetration testing simulates cyber attacks to identify network and system vulnerabilities. It is a crucial tool in strengthening overall cybersecurity and minimizing the risk of data breaches. Also known as 'ethical' or 'white-hat' hacking, it is always conducted with the consent and knowledge of the business."
              />
            </a>
          </div>
          <h2>A bit of History</h2>
          <div id="history">
            <div id="history-text">
              <p className="body-text">
                Our founder started his first ISP in late 1994 and quickly
                became passionate about the digital world. He went on to
                successfully build two ISPs by the end of the 1990s, but even in
                the mid-90s, he recognized the growing importance of data
                security. "Cracking" (as it was called then) was already a
                problem, and spam and viruses had started to become very
                problematic as more and more people began to use the internet.
                People were beginning to realize the importance of data
                security, and this led to the creation of infoSafe.biz.
              </p>
              <p className="body-text">
                Our first major product was software that worked on MS Windows
                machines and was designed to encrypt, decrypt, and shred data.
                It was well-received as an excellent product for its time, and
                it helped to establish our reputation as a reliable provider of
                cybersecurity products. Many of our consultants began their
                journey in internet and cybersecurity around the same time, and
                they have since built up an extensive wealth of knowledge and
                experience.
              </p>
              <p className="body-text">
                Over time, we expanded our focus to become a general information
                and cybersecurity business. We no longer produce software, but
                rather concentrate on how to protect it and the important data
                it can hold. This became extremely important as more and more
                data was being held on decentralized systems, and businesses
                started to work remotely while requiring access to the data that
                was once kept on a secure server at their office with no
                internet access. We recognized the growing importance of
                cybersecurity in a rapidly evolving digital world, and we
                decided to expand our range of services to help individuals and
                organizations protect themselves from a growing range of
                security risks.
              </p>
              <p className="body-text">
                Today, we offer a range of services to help our clients stay
                safe and secure in an increasingly digital world. In addition to
                the services mentioned above, we also provide consulting
                services to help our clients develop and implement effective
                cybersecurity strategies, data loss prevention, and disaster
                recovery plans. With our experienced team of cybersecurity
                experts and our comprehensive range of services, we are
                committed to helping our clients stay safe and secure in a
                constantly evolving digital world.
              </p>
            </div>
            <div id="history-card">
              <CatCard
                srcImg={isafe_front}
                altTxt="First infoSafe Software"
                title="First infoSafe Software"
                text="Our first software was released in 2004. It encrypted, decrypted and shredded digital files. An excellent product for its time. We no longer develop software but we have never forgotten where we started."
              />
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
};

export default Home;
